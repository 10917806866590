import {parseFloat, parseInt32, parseString, parseUint16, parseUint8} from "../helpers/BinaryParseHelper";
import {TFunction} from "i18next";
import {convertAwpUtValue, formatAwpUtScaleName} from "../helpers/AwpUtFormatHelper";
import {AreaChartData} from "../components/MeasurementDetails/MeasurementsAreaChart";

const SERIES_SIZE_V1 = 6021;
const SERIES_SIZE_V2 = 6101;
const SERIES_SIZE_V3 = 6120;
const SERIES_SIZE_V4 = 6018;

const NAME_SIZE = 21;
const PROBE_NAME_SIZE = 21;
const DEVICE_SERIAL_SIZE_V1_V2 = 11;
const DEVICE_SERIAL_SIZE_V3 = 30;
const DIR_NAME_SIZE = 15;
const SCREEN_DATA_SIZE = 1024 + 62;
const MEASURE_SIZE = 2080;

const B_SCAN_SIZE = 512;

export interface AwpUtSeries {
    name: string;
    probeName: string;
    deviceSerial: string;
    dirName: string;
    screen: Uint8Array;
    measure: Measure;
}

interface Measure {
    timeEtalon: number;
    speedMaterial: number;
    scale: number;
    maxThinc: number;
    minThinc: number;
    bScan: Array<number>;
    head: number;
    flagScan: number;
    currentTime: number;
    currentThinc: number;
    saveThinc: number;
    saveTime: number;
    acustContact: number;
}

export function parseUtSeries(buffer: ArrayBuffer): AwpUtSeries | null {
    let version;
    if (buffer.byteLength === SERIES_SIZE_V1) {
        version = 1;
    } else if (buffer.byteLength === SERIES_SIZE_V2) {
        version = 2;
    } else if (buffer.byteLength === SERIES_SIZE_V3) {
        version = 3;
    } else if (buffer.byteLength === SERIES_SIZE_V4) {
        version = 4;
    } else {
        return null;
    }
    let offset = 0;
    const name = parseString(buffer, offset, NAME_SIZE);
    offset += NAME_SIZE;
    const probeName = parseString(buffer, offset, PROBE_NAME_SIZE);
    offset += PROBE_NAME_SIZE;
    let deviceSerial;
    if (version === 1 || version === 2 || version === 4) {
        deviceSerial = parseString(buffer, offset, DEVICE_SERIAL_SIZE_V1_V2);
        offset += DEVICE_SERIAL_SIZE_V1_V2;
    }
    if (version === 3) {
        deviceSerial = parseString(buffer, offset, DEVICE_SERIAL_SIZE_V3);
        offset += DEVICE_SERIAL_SIZE_V3;
    }
    const dirName = parseString(buffer, offset, DIR_NAME_SIZE);
    offset += DIR_NAME_SIZE;
    const screen = new Uint8Array(buffer, offset, SCREEN_DATA_SIZE);
    offset += SCREEN_DATA_SIZE;
    const measure = parseMeasure(buffer, offset);
    offset += MEASURE_SIZE;
    return {
        name: name,
        probeName: probeName,
        deviceSerial: deviceSerial ?? "",
        dirName: dirName,
        screen: screen,
        measure: measure,
    }
}

function parseMeasure(initialBuffer: ArrayBuffer, initialOffset: number): Measure {
    let offset = 0;
    let buffer = initialBuffer.slice(initialOffset);
    const timeEtalon = parseFloat(buffer, offset);
    offset += 4;
    let speedMaterial = parseInt32(buffer, offset);
    if (speedMaterial > 100000) {
        speedMaterial = parseFloat(buffer, offset);
    }
    offset += 4;
    const scale = parseInt32(buffer, offset);
    offset += 4;
    const maxThinc = parseFloat(buffer, offset);
    offset += 4;
    const minThinc = parseFloat(buffer, offset);
    offset += 4;
    const bScan = new Float32Array(buffer, offset, B_SCAN_SIZE);
    offset += 4 * B_SCAN_SIZE;
    const head = parseUint16(buffer, offset);
    offset += 2;
    const flagScan = parseUint8(buffer, offset);
    offset += 1;
    buffer = buffer.slice(offset);
    offset = 0;
    const currentTime = parseFloat(buffer, offset);
    offset += 4;
    const currentThinc = parseFloat(buffer, offset);
    offset += 4;
    const saveThinc = parseFloat(buffer, offset);
    offset += 4;
    const saveTime = parseFloat(buffer, offset);
    offset += 4;
    const acustContact = parseUint8(buffer, offset);
    return {
        timeEtalon: timeEtalon,
        speedMaterial: speedMaterial,
        scale: scale,
        maxThinc: maxThinc,
        minThinc: minThinc,
        bScan: Array.from(bScan),
        head: head,
        flagScan: flagScan,
        currentTime: currentTime,
        currentThinc: currentThinc,
        saveThinc: saveThinc,
        saveTime: saveTime,
        acustContact: acustContact
    }
}

export function getAwpUtSeriesTitle(t: TFunction<"translation">, series: AwpUtSeries) {
    return t('scale_format', {
        scale: formatAwpUtScaleName(t, series.measure.scale)
    });
}

export function prepareAwpUtAreaChartData(series: AwpUtSeries) {
    let i = 0;
    const data = new Array<AreaChartData>();
    const scale = series.measure.scale;
    let j = (series.measure.head + 1) % 512;
    while (j !== series.measure.head){
        const value = series.measure.bScan[j];
        if (value > 0) {
            data.push({
                x: i++,
                y: [0, convertAwpUtValue(scale, value)]
            });
        }
        j = (j + 1) % 512;
    }
    return data;
}

export function getAwpUtMeasurementError(scale: number) {
    switch (scale) {
        case 0:
            return 0.05;
        case 1:
            return 0.001;
        default:
            return 0.05;
    }
}
