import React, {Fragment, Suspense, useEffect} from 'react';
import {Route, Routes, useMatch, useNavigate} from 'react-router-dom';
import './App.css';
import {Viewer} from "./components/Viewer/Viewer";
import {AppBar} from "./components/AppBar/AppBar";
import {Container} from "react-bootstrap";
import {Footer} from "./components/Footer/Footer";
import {
    AWP_DATA_STREAMING_ROUTE,
    AWP_FW_ASSIST,
    AWP_FW_EXPERT_UPDATE_ROUTE,
    AWP_FW_GSP_UPDATE_ROUTE,
    AWP_FW_LOADER,
    AWP_IPSM_BASE_ROUTE,
    AWP_IPSM_DATA_TRANSFER_ROUTE,
    AWP_IPSM_RECORD_ROUTE,
    AWP_IPSM_ROUTE,
    AWP_KRC_BASE_ROUTE,
    AWP_KRC_RECORD_ROUTE,
    AWP_KRC_ROUTE,
    AWP_LIST_ROUTE,
    AWP_MF_BASE_ROUTE,
    AWP_MF_RECORD_ROUTE,
    AWP_MF_ROUTE,
    AWP_TP_BASE_ROUTE,
    AWP_TP_RECORD_ROUTE,
    AWP_TP_ROUTE,
    AWP_TUD_BASE_ROUTE,
    AWP_TUD_RECORD_ROUTE,
    AWP_TUD_ROUTE,
    AWP_UD2301_BASE_ROUTE,
    AWP_UD2301_RECORD_ROUTE,
    AWP_UD2301_ROUTE,
    AWP_UD2303_BASE_ROUTE,
    AWP_UD2303_RECORD_ROUTE,
    AWP_UD2303_ROUTE,
    AWP_UD3701_BASE_ROUTE,
    AWP_UD3701_RECORD_ROUTE,
    AWP_UD3701_ROUTE,
    AWP_UT2A_BASE_ROUTE,
    AWP_UT2A_RECORD_ROUTE,
    AWP_UT2A_ROUTE,
    AWP_UT3EMA_BASE_ROUTE,
    AWP_UT3EMA_RECORD_ROUTE,
    AWP_UT3EMA_ROUTE,
    AWP_UT_BASE_ROUTE,
    AWP_UT_RECORD_ROUTE,
    AWP_UT_ROUTE,
    buildListPath,
    CALENDAR_ROUTE,
    EXECUTORS_ROUTE,
    HOME_ROUTE,
    LIST_BASE_ROUTE,
    LIST_ROUTE,
    MAP_ROUTE,
    RECORD_ROUTE,
    SHARED_RECORD_ROUTE
} from "./routes";
import {Welcome} from "./components/Welcome/Welcome";
import {MapExplorer} from "./components/MapExplorer/MapExplorer";
import {CalendarExplorer} from "./components/CalendarExplorer/CalendarExplorer";
import {ListExplorer} from "./components/ListExplorer/ListExplorer";
import {CALENDAR, LIST, MAP} from "./models/ExplorerMode";
import {LoadingScreen} from "./components/LoadingScreen/LoadingScreen";
import {LIST_LOCATION} from "./persistence";
import {getSavedValue} from "./helpers/PersistenceHelper";
import {Executors} from "./components/Executors/Executors";
import {useAwpRouteSection} from "./hooks/RouteHooks";
import {useGoogleApi} from './google_api/GoogleApiProvider';
import {logger, logInfo, MAX_LOG_RECORDS} from "./helpers/LogHelper";
import {testDeviceInfoParsing} from "./serial/AwpFwSerialDevice";
import {searchFw} from "./components/AwpFwGspUpdate/AwpFwSearch";
import {Redirect} from "./helpers/Redirect";
import {AwpList} from "./components/AwpList/AwpList";
import {AwpManager} from "./components/AwpManager/AwpManager";
import {
    AWP_DEVICE_IPSM,
    AWP_DEVICE_KRC,
    AWP_DEVICE_MF,
    AWP_DEVICE_TP,
    AWP_DEVICE_TUD,
    AWP_DEVICE_UD2301,
    AWP_DEVICE_UD2303,
    AWP_DEVICE_UD3701,
    AWP_DEVICE_UT,
    AWP_DEVICE_UT2A,
    AWP_DEVICE_UT3EMA
} from "./helpers/AwpHelper";
import {AwpRecordView} from "./components/AwpRecordView/AwpRecordView";
import {AwpIpsmDataTransfer} from "./components/AwpManager/AwpIpsmDataTransfer";
import {AwpFwGspUpdate} from "./components/AwpFwGspUpdate/AwpFwGspUpdate";
import {AwpFwLoader} from "./components/AwpFwLoader/AwpFwLoader";
import {AwpFwAssist} from "./components/AwpFwLoader/AwpFwAssist";
import {AwpFwExpertUpdate} from "./components/AwpFwExpertUpdate/AwpFwExpertUpdate";
import {AwpDataStreaming} from "./components/AwpDataStreaming/AwpDataStreaming";

function testFwUpdate(){
    const  deviceInfo = testDeviceInfoParsing();
    if (deviceInfo.hwVersion && deviceInfo.swVersion) {
        searchFw(deviceInfo.deviceTypeId, deviceInfo.hwVersion, deviceInfo.swVersion, "new").then(fwInfo => console.log(fwInfo));
    }
}

function App() {
    const history = useNavigate();
    const homeRouteMatch = useMatch(HOME_ROUTE);
    const awpListRouteMatch = useMatch(AWP_LIST_ROUTE);
    const sharedRecordRouteMatch = useMatch(SHARED_RECORD_ROUTE);
    const executorsListRouteMatch = useMatch(EXECUTORS_ROUTE);
    const awpRouteMatch = useAwpRouteSection();
    const googleApi = useGoogleApi();
    const isSignedIn = googleApi?.userData;
    useEffect(() => {
        logger.count().then(count => {
            if (count > MAX_LOG_RECORDS){
                logger.clear().then(b => logInfo("Logs cleared"));
            }
        })
        logInfo("Starting");
        // testFwUpdate();
    }, []);
    if (isSignedIn === undefined) {
        return <LoadingScreen/>
    }
    if (!isSignedIn && !homeRouteMatch && !sharedRecordRouteMatch && !executorsListRouteMatch && !awpListRouteMatch && !awpRouteMatch) {
        return (
            <Redirect to={HOME_ROUTE}/>
        );
    }
    const modeChangeHandler = (mode: number) => {
        switch (mode) {
            case LIST:
                history(buildListPath(getSavedValue(LIST_LOCATION, "")));
                break;
            case MAP:
                history(MAP_ROUTE);
                break;
            case CALENDAR:
                history(CALENDAR_ROUTE);
                break;
        }
    }
    return (
        <Suspense fallback={<LoadingScreen/>}>
            <Container fluid>
                <AppBar/>
                <div className="container-content">
                    <div className="container-grow">
                        <Routes>
                            <Route path={SHARED_RECORD_ROUTE} element={<Viewer/>}/>
                            <Route path={RECORD_ROUTE} element={<Viewer/>}/>
                            <Route path={MAP_ROUTE} element={<MapExplorer modeChangeListener={modeChangeHandler}/>}/>
                            <Route path={CALENDAR_ROUTE} element={<CalendarExplorer modeChangeListener={modeChangeHandler}/>}/>
                            <Route path={LIST_BASE_ROUTE} element={<ListExplorer modeChangeListener={modeChangeHandler}/>}/>
                            <Route path={LIST_ROUTE} element={<ListExplorer modeChangeListener={modeChangeHandler}/>}/>
                            <Route path={EXECUTORS_ROUTE} element={<Executors/>}/>
                            <Route path={HOME_ROUTE} element={isSignedIn ? <Redirect to={buildListPath()}/> : <Welcome/>}/>
                            <Fragment>
                                <Route path={AWP_LIST_ROUTE} element={<AwpList/>}/>
                                <Route path={AWP_TUD_BASE_ROUTE} element={<AwpManager deviceType={AWP_DEVICE_TUD}/>}/>
                                <Route path={AWP_TUD_ROUTE} element={ <AwpManager deviceType={AWP_DEVICE_TUD}/>}/>
                                <Route path={AWP_TUD_RECORD_ROUTE} element={<AwpRecordView deviceType={AWP_DEVICE_TUD}/>}/>
                                <Route path={AWP_KRC_BASE_ROUTE} element={<AwpManager deviceType={AWP_DEVICE_KRC}/>}/>
                                <Route path={AWP_KRC_ROUTE} element={<AwpManager deviceType={AWP_DEVICE_KRC}/>}/>
                                <Route path={AWP_KRC_RECORD_ROUTE} element={<AwpRecordView deviceType={AWP_DEVICE_KRC}/>}/>
                                <Route path={AWP_TP_BASE_ROUTE} element={<AwpManager deviceType={AWP_DEVICE_TP}/>}/>
                                <Route path={AWP_TP_ROUTE} element={<AwpManager deviceType={AWP_DEVICE_TP}/>}/>
                                <Route path={AWP_TP_RECORD_ROUTE} element={<AwpRecordView deviceType={AWP_DEVICE_TP}/>}/>
                                <Route path={AWP_UT_BASE_ROUTE} element={<AwpManager deviceType={AWP_DEVICE_UT}/>}/>
                                <Route path={AWP_UT_ROUTE} element={<AwpManager deviceType={AWP_DEVICE_UT}/>}/>
                                <Route path={AWP_UT_RECORD_ROUTE} element={<AwpRecordView deviceType={AWP_DEVICE_UT}/>}/>
                                <Route path={AWP_MF_BASE_ROUTE} element={<AwpManager deviceType={AWP_DEVICE_MF}/>}/>
                                <Route path={AWP_MF_ROUTE} element={<AwpManager deviceType={AWP_DEVICE_MF}/>}/>
                                <Route path={AWP_MF_RECORD_ROUTE} element={<AwpRecordView deviceType={AWP_DEVICE_MF}/>}/>
                                <Route path={AWP_UD2301_BASE_ROUTE} element={<AwpManager deviceType={AWP_DEVICE_UD2301}/>}/>
                                <Route path={AWP_UD2301_ROUTE} element={<AwpManager deviceType={AWP_DEVICE_UD2301}/>}/>
                                <Route path={AWP_UD2301_RECORD_ROUTE} element={<AwpRecordView deviceType={AWP_DEVICE_UD2301}/>}/>
                                <Route path={AWP_UD2303_BASE_ROUTE} element={<AwpManager deviceType={AWP_DEVICE_UD2303}/>}/>
                                <Route path={AWP_UD2303_ROUTE} element={<AwpManager deviceType={AWP_DEVICE_UD2303}/>}/>
                                <Route path={AWP_UD2303_RECORD_ROUTE} element={<AwpRecordView deviceType={AWP_DEVICE_UD2303}/>}/>
                                <Route path={AWP_UD3701_ROUTE} element={<AwpManager deviceType={AWP_DEVICE_UD3701}/>}/>
                                <Route path={AWP_UD3701_BASE_ROUTE} element={<AwpManager deviceType={AWP_DEVICE_UD3701}/>}/>
                                <Route path={AWP_UD3701_RECORD_ROUTE} element={<AwpRecordView deviceType={AWP_DEVICE_UD3701}/>}/>
                                <Route path={AWP_UT3EMA_ROUTE} element={<AwpManager deviceType={AWP_DEVICE_UT3EMA}/>}/>
                                <Route path={AWP_UT3EMA_BASE_ROUTE} element={<AwpManager deviceType={AWP_DEVICE_UT3EMA}/>}/>
                                <Route path={AWP_UT3EMA_RECORD_ROUTE} element={<AwpRecordView deviceType={AWP_DEVICE_UT3EMA}/>}/>
                                <Route path={AWP_UT2A_BASE_ROUTE} element={<AwpManager deviceType={AWP_DEVICE_UT2A}/>}/>
                                <Route path={AWP_UT2A_ROUTE} element={<AwpManager deviceType={AWP_DEVICE_UT2A}/>}/>
                                <Route path={AWP_UT2A_RECORD_ROUTE} element={<AwpRecordView deviceType={AWP_DEVICE_UT2A}/>}/>
                                <Route path={AWP_IPSM_BASE_ROUTE} element={<AwpManager deviceType={AWP_DEVICE_IPSM}/>}/>
                                <Route path={AWP_IPSM_ROUTE} element={<AwpManager deviceType={AWP_DEVICE_IPSM}/>}/>
                                <Route path={AWP_IPSM_RECORD_ROUTE} element={<AwpRecordView deviceType={AWP_DEVICE_IPSM}/>}/>
                                <Route path={AWP_IPSM_DATA_TRANSFER_ROUTE} element={<AwpIpsmDataTransfer/>}/>
                                <Fragment>
                                    <Route path={AWP_FW_GSP_UPDATE_ROUTE} element={<AwpFwGspUpdate/>}/>
                                    <Route path={AWP_FW_LOADER} element={<AwpFwLoader/>}/>
                                    <Route path={AWP_FW_ASSIST} element={<AwpFwAssist/>}/>
                                    <Route path={AWP_FW_EXPERT_UPDATE_ROUTE} element={<AwpFwExpertUpdate/>}/>
                                </Fragment>
                                <Route path={AWP_DATA_STREAMING_ROUTE} element={<AwpDataStreaming/>}/>
                            </Fragment>
                            <Route element={<Redirect to={HOME_ROUTE}/>}/>
                        </Routes>
                    </div>
                    <Footer/>
                </div>
            </Container>
        </Suspense>
    );
}

export default App;

