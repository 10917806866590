import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Loader} from "../Loader/Loader";
import {useEffect, useState} from "react";
import {getAwpFwDeviceCloudType} from "../../helpers/AwpFwFormatHelper";
import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
import {firebaseStorage, firestore} from "../../index";
import {AwpFwInfo} from "../../models/AwpFwInfo";
import {AwpDeviceInfo} from "../../models/AwpDeviceInfo";
import {formatNumber} from "../../helpers/FormatHelper";
import {getDownloadURL, ref} from "firebase/storage";
import {GSP, GSPS, NVP, NVT, ZIP} from "../../models/AwpFwFileType";
import {logError} from "../../helpers/LogHelper";

interface Message {
    text: string;
    isLoader: boolean;
}

interface Props {
    show: number;
    closeHandler: () => void;
    deviceInfo: AwpDeviceInfo;
    fwSelectionHandler: (fwInfo: AwpFwInfo, fileName: string, fileType: number, content: ArrayBuffer) => void;
}

function searchFw(deviceTypeId: number | undefined, hwVersion: number, swVersion: number, bootloader?: string): Promise<AwpFwInfo | null> {
    const fwCollectionRef = collection(firestore, "firmwares");
    const fwQuery = query(fwCollectionRef,
        where("deviceType", "==", getAwpFwDeviceCloudType(deviceTypeId)),
        where("status", "==", "release"),
        orderBy("SW", "desc"));
    return getDocs(fwQuery).then(snapshot => {
        const fwData = new Array<AwpFwInfo>();
        snapshot.forEach(doc => {
            const docData = doc.data();
            fwData.push({
                deviceType: docData.deviceType,
                sw: docData.SW,
                swVersion: docData.SWVersion,
                hwMin: docData.HWmin,
                hwMax: docData.HWmax,
                bootloader: docData.bootloader,
                type: docData.type,
                changeLog: docData.changeLog,
                fileId: doc.id
            } as AwpFwInfo);
        })
        let fwList = new Array<AwpFwInfo>();
        let fwInfo = null;
        let latestSw = undefined;
        let latestHw = undefined;
        for (const item of fwData) {
            if (!latestSw && !latestHw && hwVersion < item.hwMin) {
                latestSw = item.sw;
                latestHw = item.hwMin;
            }
            if (item.sw >= swVersion && hwVersion >= item.hwMin && hwVersion <= item.hwMax) {
                if (bootloader === item.bootloader || (!item.bootloader && fwList.length === 0)) {
                    fwInfo = item;
                    fwInfo.latestHw = latestHw;
                    fwInfo.latestSw = latestSw;
                    break;
                } else {
                    fwList.push(item);
                }
            }
        }
        if (fwList.length > 0 && fwList.every(fw => fw.bootloader === fwList[0].bootloader)) {
            fwInfo = fwList[0];
            fwInfo.bootloader = undefined;
            fwInfo.latestHw = latestHw;
            fwInfo.latestSw = latestSw;
        }
        return fwInfo;
    });
}

export function FwSearchDialog(props: Props) {
    const {t} = useTranslation();
    const [message, setMessage] = useState(undefined as Message | undefined)
    const [fwInfo, setFwInfo] = useState(undefined as AwpFwInfo | undefined | null);
    useEffect(() => {
        if (props.show % 2 === 1) {
            setMessage({text: t("awp_fw_device_version_awaiting_data"), isLoader: true})
            searchFw(props.deviceInfo.deviceTypeId, props.deviceInfo.hwVersion, props.deviceInfo.swVersion, props.deviceInfo.bootloader)
                .then(fwInfo => {
                    setFwInfo(fwInfo);
                    if (fwInfo) {
                        let fwInfoSw = fwInfo.swVersion ?? formatNumber(fwInfo.sw, 3, 1);
                        setMessage({text: t("awp_fw_found", {version: fwInfoSw}), isLoader: false});
                    } else {
                        setMessage({text: t("awp_fw_not_found"), isLoader: false});
                    }
                });
        }
    }, [t, props.show, props.deviceInfo]);
    const selectFw = (fwInfo: AwpFwInfo | null | undefined) => {
        if (fwInfo) {
            setFwInfo(undefined);
            setMessage({text: t("awp_fw_downloading"), isLoader: true});
            const fileRef = ref(firebaseStorage, `Firmwares/${fwInfo.fileId}`);
            getDownloadURL(fileRef)
                .then(url => fetch(url))
                .then(async response => {
                    if (response.ok) {
                        try {
                            const fwData = await response.arrayBuffer();
                            let fwType;
                            switch (fwInfo.type) {
                                case "gsp":
                                    fwType = GSP;
                                    break;
                                case "gsps":
                                    fwType = GSPS;
                                    break;
                                case "nvt":
                                    fwType = NVT;
                                    break;
                                case "nvp":
                                    fwType = NVP;
                                    break;
                                case "zip":
                                    fwType = ZIP;
                                    break;
                                default:
                                    throw new Error("Unsupported file");
                            }
                            const name = `${fwInfo.deviceType.toUpperCase()}_SW_${fwInfo.swVersion ?? fwInfo.sw.toString().replaceAll(".", "_")}.${fwInfo.type}`;
                            props.fwSelectionHandler(fwInfo, name, fwType, fwData);
                            close();
                        } catch (e) {
                            logError("Response process error", e);
                            setMessage({text: t("awp_fw_download_failed"), isLoader: false});
                        }
                    }
                }).catch(() => setMessage({text: t("awp_fw_download_failed"), isLoader: false}));
        }
    }
    const close = () => {
        props.closeHandler();
    }
    return (
        <Modal show={props.show % 2 === 1} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>{t("loader_fw_search")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message && message.isLoader && <Loader className={"mt-4"} message={message.text}/>}
                {message && !message.isLoader && <div>{message.text}</div>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={close}>{t("cancel")}</Button>
                <Button variant="primary" onClick={() => selectFw(fwInfo)}
                        disabled={fwInfo === null || fwInfo === undefined}>{t("ok")}</Button>
            </Modal.Footer>
        </Modal>
    );
}