import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ArchiveFileSystemHandle} from "../../filesystem/FileSystemHelpers";
import {HelpButton} from "../HelpButton/HelpButton";

interface Props {
    fsHandle?: ArchiveFileSystemHandle;
    disabled: boolean;
    selectClickHandler: () => void;
}

export function ArchiveLocation(props: Props) {
    const {t} = useTranslation();
    return (
        <div className="d-flex flex-grow-1 flex-column align-self-stretch">
            <div className="mx-2 single-line">{t("archive_location_prompt")}</div>
            <div className="d-flex flex-row align-self-stretch align-items-center">
                <div className="flex-grow-1 form-control form-control-sm whitespace">
                    {props.fsHandle?.handle?.name() ?? " "}
                </div>
                <Button className="mx-2" disabled={props.disabled} size="sm"
                        onClick={props.selectClickHandler}>{t("select")}</Button>
                <HelpButton text={t('awp_fs_permission_description')}/>
            </div>
        </div>
    );
}