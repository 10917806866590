import './AwpFwExpertUpdate.css';
import {useState} from "react";
import {AwpFwFileSelect} from "./AwpFwFileSelect";
import {AwpNvtUploadInstruction} from "./AwpNvtUploadInstruction";
import {AwpFwUploadProcess} from "./AwpFwUploadProcess";
import {CONFIG, GSP, GSPS, NVP, NVT, PICS} from "../../models/AwpFwFileType";
import {AwpPicsUploadInstruction} from "./AwpPicsUploadInstruction";
import {AwpFwPortSelect} from "./AwpFwPortSelect";
import {AwpFwUploadComplete} from "./AwpFwUploadComplete";
import {AwpFwUploadFailed} from "./AwpFwUploadFailed";
import {useNavigate} from "react-router-dom";
import {AWP_LIST_ROUTE} from "../../routes";
import {AwpFwSerialDevice} from "../../serial/AwpFwSerialDevice";
import {useAwpFwSerialDevice} from "../../serial/SerialPortHook";
import {AwpNvpUploadInstruction} from "./AwpNvpUploadInstruction";
import {AwpConfigUploadInstruction} from "./AwpConfigUploadInstruction";
import {AwpGspUploadInstruction} from "./AwpGspUploadInstruction";

enum FwUpdatePhase{
    FILE_SELECT,
    PORT_SELECT,
    GSP_UPLOAD_MODE_INSTRUCTION,
    NVT_UPLOAD_MODE_INSTRUCTION,
    PICS_UPLOAD_MODE_INSTRUCTION,
    NVP_UPLOAD_MODE_INSTRUCTION,
    CONFIG_UPLOAD_MODE_INSTRUCTION,
    UPLOAD_PROCESS,
    UPLOAD_COMPLETE,
    UPLOAD_FAILED
}

export function AwpFwExpertUpdate(){
    const history = useNavigate();
    const [phase, setPhase] = useState(FwUpdatePhase.FILE_SELECT);
    const [fileType, setFileType] = useState(undefined as number | undefined);
    const [content, setContent] = useState(undefined as ArrayBuffer | undefined);
    const [baudRate, setBaudRate] = useState(undefined as number | undefined);
    const device = useAwpFwSerialDevice(baudRate);
    const onFileSelect = (fileType: number, content: ArrayBuffer) => {
        setFileType(fileType);
        setContent(content);
        switch (fileType) {
            case NVT:
            case GSPS:
                setBaudRate(AwpFwSerialDevice.speedOldLoader);
                break;
            case GSP:
            case PICS:
            case NVP:
            case CONFIG:
                setBaudRate(AwpFwSerialDevice.speed921600)
                break;
            default:
                throw new Error("Unknown file type");
        }
        setPhase(FwUpdatePhase.PORT_SELECT);
    };
    const onPortSelect = async () => {
        if (device) {
            const result = await device.open();
            if (result) {
                switch (fileType){
                    case GSP:
                    case GSPS:
                        setPhase(FwUpdatePhase.GSP_UPLOAD_MODE_INSTRUCTION);
                        break;
                    case NVT:
                        setPhase(FwUpdatePhase.NVT_UPLOAD_MODE_INSTRUCTION);
                        break;
                    case PICS:
                        setPhase(FwUpdatePhase.PICS_UPLOAD_MODE_INSTRUCTION);
                        break;
                    case NVP:
                        setPhase(FwUpdatePhase.NVP_UPLOAD_MODE_INSTRUCTION);
                        break;
                    case CONFIG:
                        setPhase(FwUpdatePhase.CONFIG_UPLOAD_MODE_INSTRUCTION);
                        break;
                    default:
                        throw new Error("Unknown file type");
                }
            }
        }
    };
    const onStartUpload = () => {
        setPhase(FwUpdatePhase.UPLOAD_PROCESS);
    };
    const onUploadComplete = () => {
        setPhase(FwUpdatePhase.UPLOAD_COMPLETE);
    };
    const onUploadError = () => {
        setPhase(FwUpdatePhase.UPLOAD_FAILED);
    };
    const onUploadFinish = () => {
        history(AWP_LIST_ROUTE);
    };
    const onUploadRetry = () => {
        window.location.reload();
    };
    switch (phase){
        case FwUpdatePhase.FILE_SELECT:
            return <AwpFwFileSelect onFileSelect={onFileSelect}/>;
        case FwUpdatePhase.PORT_SELECT:
            return <AwpFwPortSelect onPortSelect={onPortSelect}/>
        case FwUpdatePhase.GSP_UPLOAD_MODE_INSTRUCTION:
            return <AwpGspUploadInstruction onContinue={onStartUpload}/>;
        case FwUpdatePhase.NVT_UPLOAD_MODE_INSTRUCTION:
            return <AwpNvtUploadInstruction onContinue={onStartUpload}/>;
        case FwUpdatePhase.PICS_UPLOAD_MODE_INSTRUCTION:
            return <AwpPicsUploadInstruction onContinue={onStartUpload}/>
        case FwUpdatePhase.NVP_UPLOAD_MODE_INSTRUCTION:
            return <AwpNvpUploadInstruction onContinue={onStartUpload}/>;
        case FwUpdatePhase.CONFIG_UPLOAD_MODE_INSTRUCTION:
            return <AwpConfigUploadInstruction onContinue={onStartUpload}/>;
        case FwUpdatePhase.UPLOAD_PROCESS:
            return <AwpFwUploadProcess device={device} fileType={fileType} content={content} onUploadComplete={onUploadComplete} onUploadError={onUploadError}/>;
        case FwUpdatePhase.UPLOAD_COMPLETE:
            return <AwpFwUploadComplete onFinish={onUploadFinish}/>;
        case FwUpdatePhase.UPLOAD_FAILED:
            return <AwpFwUploadFailed onRetry={onUploadRetry}/>;
        default:
            throw new Error("Unknown state");
    }
}