import moment from "moment/moment";
import {AwpDirectoryHandle} from "../helpers/AwpDirectoryHandle";
import {SerialDevice} from "./SerialDevice";
import {AwpSerialDevice} from "./AwpSerialDevice";
import {delay} from "../helpers/Utils";
import {AwpLocalDirectoryHandle} from "../helpers/AwpLocalDirectoryHandle";
import {logError, logger} from "../helpers/LogHelper";

const DATA_TIMEOUT = 3000;

export class AwpUsbStorageDevice extends AwpSerialDevice {

    constructor(port: SerialDevice) {
        super(port);
    }

    readRecords(fsHandle: AwpDirectoryHandle, from: Date, to: Date, move: boolean, progressCallback?: (progress: number) => void, confirmationCallback?: (count: number) => Promise<boolean>, instructionCallback?: () => Promise<void>): Promise<boolean> {
        this.isAborted = false;
        let isFirstAttempt = true;
        return this.port.open().then((isOpen) => {
            if (!isOpen) {
                return false;
            }
            const mountCommand = 'msd\r\n';
            const unmountCommand = 'msdexit\r\n';
            const encoder = new TextEncoder();
            return new Promise<void>(async (resolve, reject) => {
                await this.port.writeTextAsync(encoder, mountCommand);
                let isAlive = true;
                const decoder = new TextDecoder("windows-1251");
                let localBuffer = "";
                let timerId: NodeJS.Timeout | undefined;
                const success = () => {
                    resetTimer();
                    if (isAlive) {
                        resolve();
                    }
                    isAlive = false;
                };
                const fail = (reason: any) => {
                    resetTimer();
                    if (isAlive) {
                        reject(reason);
                    }
                    isAlive = false;
                }
                const setTimer = (timeout: number) => {
                    resetTimer();
                    timerId = setTimeout(async () => {
                        const result = await this.readUsbRecords(fsHandle, from, to, move, progressCallback, confirmationCallback, instructionCallback);
                        await delay(500);
                        await this.port.writeTextAsync(encoder, unmountCommand);
                        if (result) {
                            success();
                        } else {
                            fail(result !== undefined ? "SD card copying failed" : undefined);
                        }
                    }, timeout);
                }
                const resetTimer = () => {
                    if (timerId) {
                        clearTimeout(timerId);
                        timerId = undefined;
                    }
                }
                setTimer(DATA_TIMEOUT);
                let stopped = false;
                this.port.listen(async data => {
                    if (stopped) {
                        return;
                    }
                    localBuffer += decoder.decode(data);
                    if (localBuffer.length > 0) {
                        const errorRegEx = /.*Error Command.*/gm;
                        if (errorRegEx.test(localBuffer)) {
                            if (isFirstAttempt) {
                                resetTimer();
                                isFirstAttempt = false;
                                await delay(500);
                                localBuffer = "";
                                await this.port.writeTextAsync(encoder, mountCommand);
                                setTimer(DATA_TIMEOUT);
                            } else {
                                fail("Error");
                            }
                        }
                    }
                });
            }).then(() => {
                this.port.close();
                return true;
            }).catch(reason => {
                this.port.close();
                if (reason) {
                    throw new Error(reason);
                } else {
                    return false;
                }
            });
        });
    }

    readUsbRecords(fsHandle: AwpDirectoryHandle, from: Date, to: Date, move: boolean, progressCallback?: (progress: number) => void, confirmationCallback?: (count: number) => Promise<boolean>, instructionCallback?: () => Promise<void>): Promise<boolean | undefined> {
        return (instructionCallback ? instructionCallback() : Promise.resolve())
            .then(() => window.showDirectoryPicker().then(handle => {
                    return handle.queryPermission({mode: 'readwrite'}).then(state => {
                        if (state === 'granted') {
                            return new AwpLocalDirectoryHandle(handle);
                        } else {
                            return handle.requestPermission({mode: 'readwrite'}).then(state => {
                                if (state === 'granted') {
                                    return new AwpLocalDirectoryHandle(handle);
                                } else {
                                    return null;
                                }
                            })
                        }
                    }).then(async handle => {
                        if (handle) {
                            let count = 0;
                            let progress = 0;
                            const entries = await handle.directories();
                            for (const entry of entries) {
                                const nameFileHandle = await entry.getFileHandle(AwpUsbStorageDevice.nameFile);
                                if (nameFileHandle) {
                                    const date = await nameFileHandle.lastModified();
                                    if (date > from.getTime() && date < to.getTime()) {
                                        count++;
                                    }
                                }
                            }
                            const shouldContinue = confirmationCallback ? await confirmationCallback(count) : true;
                            if (shouldContinue) {
                                const encoder = new TextEncoder();
                                const entries = await handle.directories();
                                for (const entry of entries) {
                                    const nameFileHandle = await entry.getFileHandle(AwpUsbStorageDevice.nameFile);
                                    if (nameFileHandle) {
                                        const date = await nameFileHandle.lastModified();
                                        if (date > from.getTime() && date < to.getTime()) {
                                            const localHandle = fsHandle.getFileSystemHandle();
                                            if (localHandle) {
                                                const folderHandle = await localHandle.getDirectoryHandle(entry.name(), {create: true});
                                                const recordEntries = await entry.files();
                                                for (const recordEntry of recordEntries) {
                                                    const fileHandle = await folderHandle.getFileHandle(recordEntry.name(), {create: true});
                                                    const data = await recordEntry.getData().catch(reason => {
                                                        logError("File read error", reason);
                                                        return null;
                                                    });
                                                    if (data) {
                                                        const writerFile = await fileHandle.createWritable();
                                                        const writer = writerFile.getWriter();
                                                        await writer.write(data);
                                                        writer.releaseLock();
                                                        await writerFile.close();
                                                    }
                                                }
                                                const dateFileHandle = await folderHandle.getFileHandle(AwpUsbStorageDevice.dateFile, {create: true});
                                                const dateFile = await dateFileHandle.createWritable();
                                                const dateFileWriter = dateFile.getWriter();
                                                await dateFileWriter.write(encoder.encode(moment(date).format("DD.MM.YYYY HH.mm")));
                                                dateFileWriter.releaseLock();
                                                await dateFile.close();
                                                if (this.isAborted) {
                                                    const handle = fsHandle.getFileSystemHandle();
                                                    if (handle) {
                                                        await handle.removeEntry(entry.name(), {recursive: true});
                                                    }
                                                    break;
                                                } else {
                                                    if (move) {
                                                        const deviceHandle = handle.getFileSystemHandle();
                                                        if (deviceHandle) {
                                                            await deviceHandle.removeEntry(entry.name(), {recursive: true});
                                                        }
                                                    }
                                                }
                                                progress++;
                                                if (progressCallback) {
                                                    progressCallback(progress / count * 100);
                                                }

                                            }
                                        }
                                    }
                                }
                            }
                            return true;
                        } else {
                            return false;
                        }
                    })
                }).catch(reason => {
                    logError("Record read error", reason);
                    return undefined;
                })
            );
    }
}