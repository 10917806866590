import {TFunction} from "i18next";
import {
    FW_ARS1,
    FW_EDDYSENSE,
    FW_KRC,
    FW_LAB_DPM,
    FW_LAB_UCI,
    FW_LEEB,
    FW_LEEB_PLAIN,
    FW_MF1M,
    FW_TP2020,
    FW_TUD2,
    FW_TUD3,
    FW_UD2301,
    FW_UD2303,
    FW_UD3701,
    FW_UNKNOWN,
    FW_UT1M,
    FW_UT1MIP,
    FW_UT1MST,
    FW_UT2A,
    FW_UT3EMA
} from "../models/AwpFwUpdateDevice";

export function formatAwpFwDeviceName(t: TFunction<"translation">, device: number) {
    switch (device) {
        case FW_KRC:
            return t("krc2_display_name");
        case FW_MF1M:
            return t("mf1m_display_name");
        case FW_TUD2:
            return t("tud2_display_name");
        case FW_TUD3:
            return t("tud3_display_name");
        case FW_TP2020:
            return t("tp2020_display_name");
        case FW_UD2301:
            return t("ud2301_display_name");
        case FW_UD2303:
            return t("ud2303_display_name");
        case FW_UD3701:
            return t("ud3701_display_name");
        case FW_UT1M:
            return t("ut1m_display_name");
        case FW_UT1MIP:
            return t("ut1m_ip_display_name");
        case FW_UT1MST:
            return t("ut1m_ct_display_name");
        case FW_UT3EMA:
            return t("ut3ema_display_name");
        case FW_UT2A:
            return t("ut2a_display_name");
        case FW_ARS1:
            return t("ars1_display_name");
        case FW_LEEB:
            return t("lab_leeb_display_name");
        case FW_LEEB_PLAIN:
            return t("leeb_plain_display_name");
        case FW_LAB_UCI:
            return t("uci_display_name");
        case FW_LAB_DPM:
            return t("dpm_display_name");
        case FW_EDDYSENSE:
            return t("eddysense_display_name");
    }
    return "";
}

export function getAwpFwDeviceCloudType(deviceId?: number) : string {
    if (!deviceId) {
        return "";
    }
    switch (deviceId) {
        case FW_KRC:
            return "krc";
        case FW_MF1M:
            return "mf1m";
        case FW_TUD2:
            return "tud2";
        case FW_TUD3:
            return "tud3";
        case FW_TP2020:
            return "tp";
        case FW_UD2301:
            return "ud2301";
        case FW_UD2303:
            return "ud2303";
        case FW_UD3701:
            return "ud3701";
        case FW_UT1M:
            return "ut1m";
        case FW_UT1MIP:
            return "ut1mip";
        case FW_UT1MST:
            return "ut1mst";
        case FW_UT3EMA:
            return "ut3mema";
        case FW_UT2A:
            return "ut2a";
        case FW_ARS1:
            return "ars1";
        case FW_LEEB:
            return "leeb";
        case FW_LEEB_PLAIN:
            return "leeb_plain";
        case FW_LAB_UCI:
            return "uci";
        case FW_LAB_DPM:
            return "dpm";
        case FW_EDDYSENSE:
            return "vd1m";
        default:
            return "";
    }
}

export function getAwpFwDeviceType(id : number){
    switch (id){
        case 6:
        case 8:
            return FW_TUD2;
        case 9:
            return FW_TUD3;
        case 20:
            return FW_KRC;
        case 1:
        case 43:
            return FW_TP2020;
        case 27:
            return FW_UT1M;
        case 44:
            return FW_UT1MIP;
        case 45:
            return FW_UT1MST;
        case 35:
            return FW_MF1M;
        case 21:
            return FW_UD2301;
        case 42:
            return FW_UD2303;
        case 24:
            return FW_UD3701;
        case 34:
            return FW_UT3EMA;
        case 41:
            return FW_UT2A;
        case 16:
            return FW_ARS1;
        case 54:
            return FW_LEEB;
        case 53:
            return FW_LEEB_PLAIN;
        case 48:
            return FW_LAB_UCI;
        case 11:
            return FW_LAB_DPM;
        case 55:
            return FW_EDDYSENSE;
        default:
            return FW_UNKNOWN;
    }
}