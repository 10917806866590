import {Link, useNavigate} from 'react-router-dom';
import './AppBar.css';
import logoMain from './logo_main.png';
import {Accordion, AccordionContext, Button, Dropdown, SplitButton, useAccordionButton} from "react-bootstrap";
import DropdownItem from "react-bootstrap/DropdownItem";
import {Languages} from "../../locale/Languages";
import {useTranslation} from "react-i18next";
import {useLocale} from "../../hooks/LocaleHook";
import {useIsFullAppBar} from "../../hooks/ScreenSizeHooks";
import IconMenuSvg from "../Icons/IconMenuSvg";
import {Fragment, useContext, useEffect, useState} from "react";
import {
    AWP_DATA_STREAMING_ROUTE,
    AWP_FW_GSP_UPDATE_ROUTE,
    AWP_LIST_ROUTE,
    EXECUTORS_ROUTE,
    HOME_ROUTE,
    Sections
} from "../../routes";
import {HorizontalDivider} from "../Divider/HorizontalDivider";
import {DEMO_APP_URL} from "../../AppSettings";
import {GoogleSignInButton} from "../GoogleSignInButton/GoogleSignInButton";
import {
    AWP_DATA_STREAMING_SECTION, AWP_FW_SECTION,
    useAwpRouteSection,
    useExecutorRouteSection,
    useWelcomeRouteSection
} from "../../hooks/RouteHooks";
import {buildAwpOptions} from "../../helpers/AwpOptions";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import {GoogleSignInNotAvailableDialog} from "../GoogleSignInNotAvailableDialog/GoogleSignInNotAvailableDialog";
import {useGoogleApi} from "../../google_api/GoogleApiProvider";
import {logger} from "../../helpers/LogHelper";

function AppBarToggleButton(props: { eventKey: string }) {
    const currentEventKey = useContext(AccordionContext);
    const isCurrentEventKey = currentEventKey === props.eventKey;
    const accordionHandler = useAccordionButton(props.eventKey, () => {
    });
    return (
        <Button id="accordion-toggle" variant={isCurrentEventKey ? "primary" : "outline-primary"}
                className="me-4 app-bar-menu-button"
                onClick={accordionHandler}>
            <IconMenuSvg/>
        </Button>
    );
}

export function AppBar() {
    const accordionKey = "0";
    const history = useNavigate();
    const isNormalScreen = useIsFullAppBar();
    const googleData = useGoogleApi();
    let section = Sections.ARCHIVE;
    if (useExecutorRouteSection()) {
        section = Sections.FIND_EXECUTOR;
    }
    if (useWelcomeRouteSection()) {
        section = Sections.WELCOME;
    }
    const awpRouteSection = useAwpRouteSection();
    if (awpRouteSection !== undefined) {
        section = Sections.AWP;
    }
    const {t, i18n} = useTranslation();
    const locale = useLocale();
    const [showSignInNotAvailableDialog, setShowSignInNotAvailableDialog] = useState(false);
    const closeAppBar = () => {
        if (document.querySelector(".accordion-collapse")?.classList?.contains("show")) {
            document.getElementById("accordion-toggle")?.click();
        }
    }
    useEffect(() => {
        document.addEventListener("click", closeAppBar);
        return () => {
            document.removeEventListener("click", closeAppBar);
        }
    }, []);
    const goToExecutorsSearch = () => {
        history(EXECUTORS_ROUTE);
    };
    const goToAwpList = () => {
        history(AWP_LIST_ROUTE);
    }
    const login = () => {
        if (googleData && googleData.signIn) {
            googleData.signIn();
            history(HOME_ROUTE);
        }
    }
    const logout = () => {
        googleData?.signOut?.();
    }
    let isSignedIn : boolean | undefined;
    if (googleData !== undefined && googleData.userData !== undefined){
        isSignedIn = googleData.userData !== null;
    }
    const goToArchive = () => {
        if (isSignedIn !== undefined && isSignedIn) {
            history(HOME_ROUTE);
        } else {
            login();
        }
    };
    const goToDemoArchive = () => {
        window.open(DEMO_APP_URL, "_blank");
    };
    if (isNormalScreen) {
        return (
            <div className={"app-bar no-select"}>
                <div className={"app-bar-container"}>
                    <div className="d-flex align-items-center">
                        <Link to={"/"}><img src={logoMain} alt="NOVOTEST"/></Link>
                        <span className={`app-bar-link ${section === Sections.FIND_EXECUTOR ? "active" : ""}`}
                              onClick={goToExecutorsSearch}>{t("search_for_executor")}</span>
                        <span className={`app-bar-link ${section === Sections.ARCHIVE ? "active" : ""}`}
                              onClick={goToArchive}>{t("archive")}</span>
                        <Dropdown>
                            <DropdownToggle as="span"
                                            className={`app-bar-link ${section === Sections.AWP ? "active" : ""}`}>
                                <span onClick={(e) => {
                                    e.stopPropagation();
                                    goToAwpList();
                                }}>{t("awp")}</span>
                            </DropdownToggle>
                            <DropdownMenu className="shadow">
                                <div className='dropdown-item-title'>{t("records_transfer")}</div>
                                {buildAwpOptions(t).map((item, i) => <DropdownItem key={`nav-awp-list-item-${i}`} className={`${(item.deviceType === awpRouteSection) ? "dropdown-item-active" : ""}`}
                                                                                   onClick={() => history(item.path)}>{item.title}</DropdownItem>)}
                                <HorizontalDivider className="my-2"/>
                                <div className='dropdown-item-title'>{t("data_streaming")}</div>
                                <DropdownItem key={`nav-awp-list-item-fw`} className={`${(awpRouteSection === AWP_DATA_STREAMING_SECTION) ? "dropdown-item-active" : ""}`}
                                              onClick={() => history(AWP_DATA_STREAMING_ROUTE)}>{t('awp_ut3ema')}</DropdownItem>
                                <HorizontalDivider className="my-2"/>
                                <DropdownItem key={`nav-awp-list-item-fw`} className={`${(awpRouteSection === AWP_FW_SECTION) ? "dropdown-item-active" : ""}`}
                                              onClick={() => history(AWP_FW_GSP_UPDATE_ROUTE)}>{t('firmware_update')}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        {isSignedIn !== undefined && !isSignedIn &&
                            <span className={`app-bar-link accent`} onClick={goToDemoArchive}>{t("archive_demo")}</span>
                        }
                    </div>
                    <div className="mx-4 d-flex justify-content-end">
                        {isSignedIn !== undefined && isSignedIn &&
                            <div className="email-button">
                                <SplitButton id="dropdown-basic-button"
                                             title={googleData?.userData?.email}>
                                    <Dropdown.Item onClick={logout}>{t("sign_out")}</Dropdown.Item>
                                </SplitButton>
                            </div>
                        }
                        {isSignedIn !== undefined && !isSignedIn &&
                            <GoogleSignInButton clickHandler={login}/>}
                        <SplitButton className="ms-4" id="language-dropdown-basic-button"
                                     title={Languages.find(l => l.code === locale)?.shortName ?? Languages[0].shortName}>
                            {Languages.map(l => <DropdownItem key={l.code}
                                                              onClick={() => i18n.changeLanguage(l.code)}>{l.fullName}</DropdownItem>)}
                        </SplitButton>
                    </div>
                </div>
                <GoogleSignInNotAvailableDialog show={showSignInNotAvailableDialog} closeHandler={() => setShowSignInNotAvailableDialog(false)}/>
            </div>
        );
    } else {
        return (
            <Accordion>
                <div className={"app-bar app-bar-small-screen no-select"}>
                    <div className="d-flex flex-row align-items-center">
                        <Link to={"/"} className="mx-2 flex-grow-1 d-flex justify-content-center">
                            <img src={logoMain} alt="NOVOTEST"/>
                        </Link>
                        <AppBarToggleButton eventKey={accordionKey}/>
                    </div>
                    <Accordion.Collapse eventKey={accordionKey}>
                        <div className="d-flex flex-column justify-content-around align-items-end mx-4 mt-1">
                            <HorizontalDivider/>
                            <span
                                className={`app-bar-link app-bar-small-screen align-self-stretch ${section === Sections.FIND_EXECUTOR ? "active" : ""}`}
                                onClick={goToExecutorsSearch}>{t("search_for_executor")}</span>
                            <HorizontalDivider/>
                            <span
                                className={`app-bar-link app-bar-small-screen align-self-stretch ${section === Sections.ARCHIVE ? "active" : ""}`}
                                onClick={goToArchive}>{t("archive")}</span>
                            <HorizontalDivider/>
                            <Dropdown className={"align-self-stretch"}>
                                <div onClick={e => e.stopPropagation()}>
                                    <DropdownToggle as="span"
                                                    className={`app-bar-link app-bar-small-screen align-self-stretch ${section === Sections.AWP ? "active" : ""}`}>
                                        <span onClick={(e) => {
                                            e.stopPropagation();
                                            goToAwpList();
                                            closeAppBar();
                                        }}>{t("awp")}</span>
                                    </DropdownToggle>
                                </div>
                                <DropdownMenu className="shadow">
                                    <div className='dropdown-item-title'>{t("records_transfer")}</div>
                                    {buildAwpOptions(t).map((item, i) => <DropdownItem
                                        key={`nav-awp-list-small-item-${i}`}
                                        className={`${(item.deviceType === awpRouteSection) ? "dropdown-item-active" : ""}`}
                                        onClick={() => history(item.path)}>{item.title}</DropdownItem>)}
                                    <HorizontalDivider className="my-2"/>
                                    <div className='dropdown-item-title'>{t("data_streaming")}</div>
                                    <DropdownItem
                                        key={`nav-awp-list-small-item-fw`}
                                        className={`${(awpRouteSection === AWP_DATA_STREAMING_SECTION) ? "dropdown-item-active" : ""}`}
                                        onClick={() => history(AWP_DATA_STREAMING_ROUTE)}>{t('awp_ut3ema')}</DropdownItem>
                                    <HorizontalDivider className="my-2"/>
                                    <DropdownItem
                                        key={`nav-awp-list-small-item-fw`}
                                        className={`${(awpRouteSection === AWP_FW_SECTION) ? "dropdown-item-active" : ""}`}
                                        onClick={() => history(AWP_FW_GSP_UPDATE_ROUTE)}>{t('firmware_update')}</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <HorizontalDivider/>
                            {isSignedIn !== undefined && !isSignedIn &&
                                <Fragment>
                                     <span className={`app-bar-link accent app-bar-small-screen align-self-stretch`}
                                           onClick={goToDemoArchive}>{t("archive_demo")}</span>
                                    <HorizontalDivider/>
                                </Fragment>
                            }
                            <div className="mb-2"/>
                            {isSignedIn !== undefined && isSignedIn  &&
                                <div className="email-button app-bar-small-screen mb-2"
                                     onClick={event => event.stopPropagation()}>
                                    <SplitButton id="dropdown-basic-button"
                                                 title={googleData?.userData?.email}>
                                        <Dropdown.Item onClick={logout}>{t("sign_out")}</Dropdown.Item>
                                    </SplitButton>
                                </div>
                            }
                            {isSignedIn !== undefined && !isSignedIn &&
                                <div onClick={event => event.stopPropagation()}>
                                    <GoogleSignInButton className="mb-2"
                                                        clickHandler={login}/>
                                </div>
                            }
                            <div onClick={event => event.stopPropagation()}>
                                <SplitButton className="ms-4 mb-2" id="language-dropdown-basic-button"
                                             title={Languages.find(l => l.code === locale)?.shortName ?? Languages[0].shortName}>
                                    {Languages.map(l => <DropdownItem key={l.code}
                                                                      onClick={() => i18n.changeLanguage(l.code)}>{l.fullName}</DropdownItem>)}
                                </SplitButton>
                            </div>
                        </div>
                    </Accordion.Collapse>
                </div>
                <GoogleSignInNotAvailableDialog show={showSignInNotAvailableDialog} closeHandler={() => setShowSignInNotAvailableDialog(false)}/>
            </Accordion>
        );
    }
}