import {Button, Dropdown, DropdownButton, Form, ProgressBar} from "react-bootstrap";
import {FwSelectDialog} from "./FwSelectDialog";
import {FwSearchDialog} from "./FwSearchDialog";
import {ZipFileSelectDialog} from "./ZipFileSelectDialog";
import {FwAttentionDialog} from "./FwAttentionDialog";
import "./AwpFwLoader.css"
import React, {Fragment, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {AwpDeviceInfo} from "../../models/AwpDeviceInfo";
import {formatAwpFwDeviceName} from "../../helpers/AwpFwFormatHelper";
import {AwpFwInfo} from "../../models/AwpFwInfo";
import {CONFIG, GSP, GSPS, NVP, NVT, PICS, ZIP} from "../../models/AwpFwFileType";
import {parseGspInfo} from "../../models/AwpGspFwInfo";
import {VerticalDivider} from "../Divider/VerticalDivider";
import {StartAssistDialog} from "./StartAssistDialog";
import {StopAssistDialog} from "./StopAssistDialog";
import mqtt from "mqtt";
import {
    MQTT_BROKER_PASSWORD,
    MQTT_BROKER_URL,
    MQTT_BROKER_USERNAME,
    MQTT_PING_SEND_INTERVAL_MS,
    MQTT_PING_TIMEOUT_MS,
    MQTT_TOPIC_CLIENT,
    MQTT_TOPIC_SUPPORT
} from "../../AppSettings";
import {logError} from "../../helpers/LogHelper";
import {
    formatMqttState,
    STATE_AWAITING_CLIENT,
    STATE_CLIENT_ONLINE,
    STATE_CLIENT_TERMINATED_SESSION,
    STATE_CONNECTING,
    STATE_ERROR
} from "../../mqtt/MqttState";
import {
    MqttTextMessage,
    parseMqttMessage,
    sendAutoDetectStateMessage,
    sendChatMessage,
    sendClientTerminatedSessionMessage,
    sendLowBaudRateStateMessage,
    sendPingMessage,
    sendPortConnectCommand,
    sendPortDisconnectCommand,
    sendStartFwWriteMessage,
    sendStopFwWriteMessage,
    sendSupportConnectedMessage,
    sendTerminalMessage
} from "../../mqtt/MqttHelper";
import {
    MQTT_CLIENT_FW_DATA,
    MQTT_MESSAGE_AUTODETECT_STATE,
    MQTT_MESSAGE_CHAT,
    MQTT_MESSAGE_CLIENT_STATE,
    MQTT_MESSAGE_COM_PORT_ENUMERATION,
    MQTT_MESSAGE_DEVICE_INFO,
    MQTT_MESSAGE_FW_WRITE_PROGRESS,
    MQTT_MESSAGE_LOW_BAUD_RATE_STATE,
    MQTT_MESSAGE_PORT_STATE,
    MQTT_MESSAGE_SUPPORT_DISCONNECTED,
    MQTT_MESSAGE_TERMINAL
} from "../../mqtt/MqttMessage";
import {
    checkFwCompatibility,
    CONNECTION_STATE_CONNECTED,
    CONNECTION_STATE_CONNECTING,
    CONNECTION_STATE_NOT_CONNECTED,
    formatConnectionState,
    FwLoaderClientFirmwareData,
    FwLoaderState,
    FwLoaderStatusMessage
} from "./AwpFwLoaderCommon";
import {AssistConnectDialog} from "./AssistConnectionDialog";
import {HelpButton} from "../HelpButton/HelpButton";
import {FwTransferDialog} from "./FwTransferDialog";


export function AwpFwAssist() {
    const {t} = useTranslation();
    const [assistantCode, setAssistantCode] = useState(undefined as string | undefined);
    const [previousAssistantCode, setPreviousAssistantCode] = useState(undefined as string | undefined);
    const [assistantState, setAssistantState] = useState(undefined as number | undefined);
    const [mqttClient, setMqttClient] = useState(undefined as mqtt.MqttClient | undefined);
    const [showStartAssistDialog, setShowStartAssistDialog] = useState(false);
    const [showStopAssistDialog, setShowStopAssistDialog] = useState(false);
    const [showAssistConnectDialog, setShowAssistConnectDialog] = useState(0);
    const [status, setStatus] = useState({text: ""} as FwLoaderStatusMessage)
    const [progress, setProgress] = useState(undefined as number | undefined);
    const [showFwSelectDialog, setShowFwSelectDialog] = useState(0);
    const [showFindFwDialog, setShowFindFwDialog] = useState(0);
    const [showZipFileSelectDialog, setShowZipFileSelectDialog] = useState(0);
    const [attentionDialogMessage, setAttentionDialogMessage] = useState(undefined as string | undefined);
    const [autoDetect, setAutoDetect] = useState(true);
    const [slowDevice, setSlowDevice] = useState(false);
    const [availableDevices, setAvailableDevice] = useState(undefined as SerialPortInfo[] | undefined);
    const [deviceInfo, setDeviceInfo] = useState(undefined as AwpDeviceInfo | null | undefined);
    const [portState, setPortState] = useState(CONNECTION_STATE_NOT_CONNECTED);
    const [terminalString, setTerminalString] = useState(undefined as string | undefined);
    const [chatString, setChatString] = useState(undefined as string | undefined);
    const [connectionMessageSendRequest, setConnectionMessageSendRequest] = useState(false);
    const [fwInfo, setFwInfo] = useState(undefined as AwpFwInfo | undefined);
    const [fwFileName, setFwFileName] = useState(undefined as string | undefined);
    const [fwFileType, setFwFileType] = useState(undefined as number | undefined);
    const [fwTransferContent, setFwTransferContent] = useState(undefined as ArrayBuffer | undefined);
    const [fwTransferProgress, setFwTransferProgress] = useState(undefined as number | undefined);
    const [fwData, setFwData] = useState(undefined as FwLoaderClientFirmwareData | undefined);
    const fwName = useMemo(() => {
        return fwData?.name ?? t("loader_fw_not_selected");
    }, [fwData, t]);
    const terminalInputRef = useRef<HTMLInputElement>(null);
    const sendTerminalString = () => {
        if (terminalInputRef.current) {
            const text = terminalInputRef.current.value;
            if (text && text.length > 0 && portState === CONNECTION_STATE_CONNECTED && assistantState === STATE_CLIENT_ONLINE) {
                if (mqttClient && assistantCode) {
                    sendTerminalMessage(mqttClient, assistantCode, MQTT_TOPIC_SUPPORT, text);
                }
                terminalInputRef.current.value = "";
            }
        }
    };
    const terminalOutputRef = useRef<HTMLTextAreaElement>(null);
    const [isTerminalOutputOnBottom, setTerminalOutputOnBottom] = useState(true);
    const terminalOutputOnScroll = (e: any) => {
        if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 10) {
            setTerminalOutputOnBottom(true);
        } else {
            setTerminalOutputOnBottom(false);
        }
    };
    const [terminalText, setTerminalText] = useState("");
    useEffect(() => {
        if (terminalOutputRef.current) {
            if (isTerminalOutputOnBottom) {
                terminalOutputRef.current.scrollTop = terminalOutputRef.current.scrollHeight;
            }
        }
    }, [terminalText]);
    const chatInputRef = useRef<HTMLInputElement>(null);
    const sendChatString = () => {
        if (chatInputRef.current) {
            const text = chatInputRef.current.value;
            if (text && text.length > 0) {
                chatInputRef.current.value = "";
                if (mqttClient && assistantCode) {
                    sendChatMessage(mqttClient, assistantCode, MQTT_TOPIC_SUPPORT, text + "\n");
                    setChatText(prevState => prevState + ">>> " + text + "\n");
                }
            }
        }
    };
    const chatOutputRef = useRef<HTMLTextAreaElement>(null);
    const [isChatOutputOnBottom, setChatOutputOnBottom] = useState(true);
    const chatOutputOnScroll = (e: any) => {
        if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 10) {
            setChatOutputOnBottom(true);
        } else {
            setChatOutputOnBottom(false);
        }
    };
    const [chatText, setChatText] = useState("");
    useEffect(() => {
        if (chatOutputRef.current) {
            if (isChatOutputOnBottom) {
                chatOutputRef.current.scrollTop = chatOutputRef.current.scrollHeight;
            }
        }
    }, [chatText]);
    useEffect(() => {
        if (connectionMessageSendRequest) {
            if (mqttClient && assistantCode) {
                sendSupportConnectedMessage(mqttClient, assistantCode, MQTT_TOPIC_SUPPORT);
            }
            setConnectionMessageSendRequest(false);
        }
    }, [connectionMessageSendRequest]);
    const toggleConnection = async () => {
        if (mqttClient && assistantCode) {
            if (portState !== CONNECTION_STATE_NOT_CONNECTED) {
                sendPortDisconnectCommand(mqttClient, assistantCode, MQTT_TOPIC_SUPPORT);
            } else {
                setAvailableDevice(undefined);
                setShowAssistConnectDialog(v => v + 1);
            }
        }
    };
    const toggleAutoDetect = (state: boolean) => {
        if (mqttClient && assistantCode) {
            sendAutoDetectStateMessage(mqttClient, assistantCode, MQTT_TOPIC_SUPPORT, state);
        }
        setAutoDetect(state);
    };
    const toggleSlowDevice = (state: boolean) => {
        if (mqttClient && assistantCode) {
            sendLowBaudRateStateMessage(mqttClient, assistantCode, MQTT_TOPIC_SUPPORT, state);
        }
        setSlowDevice(state)
    };
    const selectLocalFile = async () => {
        const pickerOptions = {
            types: [
                {
                    description: 'Novotest New FW',
                    accept: {
                        'application/nvt': ['.gsp', '.gsps']
                    }
                },
                {
                    description: 'Novotest FW',
                    accept: {
                        'application/nvt': ['.nvt']
                    }
                },
                {
                    description: 'Novotest PICS',
                    accept: {
                        'application/nvt': ['.bin']
                    }
                },
                {
                    description: 'Novotest Full',
                    accept: {
                        'application/nvt': ['.nvp']
                    }
                },
                {
                    description: 'Novotest Config',
                    accept: {
                        'application/nvt': ['.ncf']
                    }
                }
            ],
            excludeAcceptAllOption: true,
            multiple: false
        };
        const [fileHandle] = await window.showOpenFilePicker(pickerOptions);
        let fileType;
        if (fileHandle.name.endsWith(".gsp")) {
            fileType = GSP;
        } else if (fileHandle.name.endsWith(".gsps")) {
            fileType = GSPS;
        } else if (fileHandle.name.endsWith(".nvt")) {
            fileType = NVT;
        } else if (fileHandle.name.endsWith(".bin")) {
            fileType = PICS;
        } else if (fileHandle.name.endsWith(".nvp")) {
            fileType = NVP;
        } else if (fileHandle.name.endsWith(".ncf")) {
            fileType = CONFIG;
        } else {
            throw new Error("Wrong file type");
        }
        const file = await fileHandle.getFile();
        const content = await file.arrayBuffer();
        let info;
        if (fileType === GSP || fileType === GSPS) {
            info = parseGspInfo(content)
        }
        setFwInfo(info);
        setFwFileName(fileHandle.name);
        setFwFileType(fileType);
        setFwTransferContent(content);
    };
    const onCloudFwReady = (fwInfo: AwpFwInfo, name: string, type: number, content: ArrayBuffer) => {
        setFwInfo(fwInfo);
        setFwFileName(name);
        setFwFileType(type);
        setFwTransferContent(content);
    };
    const fwTransferHandler = (isSuccess?: boolean) => {
        setFwTransferContent(undefined);
        setFwInfo(undefined);
        setFwFileName(undefined);
        setFwFileType(undefined);
    }
    const onZipFwReady = (name: string) => {
        writeFw(name, true);
    };
    const writeFw = (subFileName?: string, skipCheck: boolean = false) => {
        if (progress !== undefined && (progress >= 100 || progress < 0)) {
            if (mqttClient && assistantCode) {
                sendStopFwWriteMessage(mqttClient, assistantCode, MQTT_TOPIC_SUPPORT);
            }
            return;
        }
        const dataType = fwData?.type;
        if (portState === CONNECTION_STATE_CONNECTED && assistantState === STATE_CLIENT_ONLINE && dataType) {
            let writeAllowed;
            if (skipCheck) {
                writeAllowed = true;
            } else {
                const fwCompatibility = checkFwCompatibility(deviceInfo, fwData);
                if (fwCompatibility !== undefined) {
                    if (fwCompatibility) {
                        writeAllowed = true;
                    } else {
                        setAttentionDialogMessage(t("loader_fw_not_compatible"));
                        writeAllowed = false;
                    }
                } else {
                    setAttentionDialogMessage(t("loader_cant_check_fw_compatibility"));
                    writeAllowed = false;
                }
            }
            if (writeAllowed) {
                if (dataType === ZIP && subFileName === undefined) {
                    toggleZipFileSelectDialog();
                } else {
                    if (mqttClient && assistantCode && fwData) {
                        setFwTransferProgress(undefined);
                        setStatus({text: t("assist_fw_awaiting_confirmation"), isError: false});
                        sendStartFwWriteMessage(mqttClient, assistantCode, MQTT_TOPIC_SUPPORT, subFileName);
                        setProgress(0);
                    }
                }
            }
        }
    };
    const canSendChat = useMemo(() => {
        return mqttClient && assistantCode && assistantState === STATE_CLIENT_ONLINE;
    }, [mqttClient, assistantCode, assistantState]);
    const toggleFwSelectDialog = () => {
        setShowFwSelectDialog(v => v + 1)
    };
    const toggleFwSearchDialog = () => {
        setShowFindFwDialog(v => v + 1);
    };
    const toggleZipFileSelectDialog = () => {
        setShowZipFileSelectDialog(v => v + 1);
    };
    const fwWriteConfirmationHandle = (isConfirmed: boolean) => {
        setAttentionDialogMessage(undefined);
        if (isConfirmed) {
            writeFw(undefined, true);
        }
    }
    const displayDeviceData = useMemo(() => {
        if (deviceInfo && deviceInfo.deviceTypeId) {
            let result = formatAwpFwDeviceName(t, deviceInfo.deviceTypeId);
            if (result) {
                if (deviceInfo.hwVersion) {
                    result += ` v.${deviceInfo.hwVersion}`;
                }
                if (deviceInfo.swVersion) {
                    result += `, ${t("loader_firmware", {version: deviceInfo.swVersion})}`;
                }
                return result;
            }
        }
        return undefined;
    }, [deviceInfo, t]);
    const assistHandler = () => {
        if (assistantCode) {
            setShowStopAssistDialog(true);
        } else {
            setShowStartAssistDialog(true);
        }
    };
    const assistStartDialogHandler = (requestCode?: string) => {
        if (requestCode) {
            if (previousAssistantCode !== requestCode) {
                setChatText("");
                setPreviousAssistantCode(requestCode);
            }
            setAssistantCode(requestCode);
        }
        setShowStartAssistDialog(false);
    };
    const assistStopDialogHandler = (confirmed: boolean) => {
        if (confirmed) {
            setAssistantCode(undefined);
        }
        setShowStopAssistDialog(false);
    };
    const assistConnectDialogHandler = (device?: SerialPortInfo) => {
        setShowAssistConnectDialog(v => v + 1);
        if (mqttClient && assistantCode && device) {
            sendPortConnectCommand(mqttClient, assistantCode, MQTT_TOPIC_SUPPORT, device);
        }

    }
    const mqttMessageHandler = (mqttMessage: MqttTextMessage) => {
        switch (mqttMessage.type) {
            case MQTT_MESSAGE_SUPPORT_DISCONNECTED:
                setAssistantState(STATE_CLIENT_TERMINATED_SESSION);
                break;
            case MQTT_MESSAGE_TERMINAL:
                setTerminalString(mqttMessage.text);
                break;
            case MQTT_MESSAGE_CHAT:
                setChatString(mqttMessage.text);
                break;
            case MQTT_MESSAGE_PORT_STATE:
                setPortState(mqttMessage.value as number);
                break;
            case MQTT_MESSAGE_AUTODETECT_STATE:
                toggleAutoDetect(mqttMessage.value as boolean);
                break;
            case MQTT_MESSAGE_LOW_BAUD_RATE_STATE:
                toggleSlowDevice(mqttMessage.value as boolean);
                break;
            case MQTT_MESSAGE_DEVICE_INFO:
                setDeviceInfo(mqttMessage.value);
                break;
            case MQTT_CLIENT_FW_DATA:
                setFwData(mqttMessage.value as FwLoaderClientFirmwareData);
                break;
            case MQTT_MESSAGE_FW_WRITE_PROGRESS:
                if (mqttMessage.value !== undefined) {
                    let progressValue = mqttMessage.value as number;
                    setProgress(progressValue);
                    if (progressValue < 0) {
                        setStatus({text: t("loader_fw_upload_error"), isError: true});
                    } else if (progressValue >= 100) {
                        setStatus({text: t("loader_fw_upload_complete")});
                    } else {
                        setStatus({text: t("assist_fw_copying")});
                    }
                } else {
                    setProgress(undefined);
                    setStatus({text: ""});
                }
                break;
            case MQTT_MESSAGE_COM_PORT_ENUMERATION:
                setAvailableDevice(mqttMessage.value as Array<SerialPortInfo>);
                break;
            case MQTT_MESSAGE_CLIENT_STATE:
                const state = mqttMessage.value as FwLoaderState;
                setPortState(state.portState);
                setChatText(state.chatText);
                setTerminalText(state.terminalText);
                setDeviceInfo(state.deviceInfo);
                setSlowDevice(state.slowDevice);
                setAutoDetect(state.autodetect);
                setProgress(state.progress);
                setFwData(state.fwData);
                break;
        }
    };
    useEffect(() => {
        if (terminalString) {
            setTerminalText(prevState => prevState + terminalString);
            setTerminalString(undefined);
        }
    }, [terminalString]);
    useEffect(() => {
        if (chatString) {
            setChatText(prevState => prevState + "<<< " + chatString);
            setChatString(undefined);
        }
    }, [chatString]);
    useEffect(() => {
        let client: mqtt.MqttClient;
        let listenInterval: NodeJS.Timeout | undefined;
        let pingInterval: NodeJS.Timeout | undefined;
        if (assistantCode) {
            const restartTimeout = () => {
                if (listenInterval) {
                    clearTimeout(listenInterval);
                }
                listenInterval = setTimeout(() => {
                    setAssistantState(s => s === STATE_CLIENT_ONLINE ? STATE_AWAITING_CLIENT : s);
                }, MQTT_PING_TIMEOUT_MS);
            }
            setAssistantState(STATE_CONNECTING);
            client = mqtt.connect(MQTT_BROKER_URL, {
                username: MQTT_BROKER_USERNAME,
                password: MQTT_BROKER_PASSWORD,
                clean: true
            });
            client.on('connect', () => {
                setAssistantState(STATE_AWAITING_CLIENT);
                setMqttClient(client);
                setConnectionMessageSendRequest(true);
            });
            client.on('error', (error: Error) => {
                logError("Mqtt error", error);
                setAssistantState(STATE_ERROR);
                if (listenInterval) {
                    clearTimeout(listenInterval);
                    listenInterval = undefined;
                }
                if (pingInterval) {
                    clearInterval(pingInterval);
                    pingInterval = undefined;
                }
            });
            client.on('message', (topic: string, message: Buffer) => {
                setAssistantState(STATE_CLIENT_ONLINE);
                restartTimeout();
                const mqttMessage = parseMqttMessage(message);
                if (mqttMessage) {
                    mqttMessageHandler(mqttMessage);
                }
            });
            client.subscribe(`${assistantCode}/${MQTT_TOPIC_CLIENT}`, {qos: 2});
            pingInterval = setInterval(() => sendPingMessage(client, assistantCode, MQTT_TOPIC_SUPPORT), MQTT_PING_SEND_INTERVAL_MS);
        }
        return () => {
            if (pingInterval) {
                clearInterval(pingInterval);
            }
            if (listenInterval) {
                clearTimeout(listenInterval);
            }
            if (client) {
                sendClientTerminatedSessionMessage(client, assistantCode!, MQTT_TOPIC_SUPPORT, () => client.end(true));
            }
            setAssistantState(undefined);
            setMqttClient(undefined);
        }
    }, [assistantCode]);
    const handleTerminalInputKeyDown = (key: React.KeyboardEvent<HTMLInputElement>) => {
        if (key.key === 'Enter') {
            sendTerminalString();
        }
    };
    const handleChatInputKeyDown = (key: React.KeyboardEvent<HTMLInputElement>) => {
        if (key.key === 'Enter') {
            sendChatString();
        }
    };
    const isConnectButtonDisabled = useMemo(() => {
        return assistantCode === undefined || portState === CONNECTION_STATE_CONNECTING || progress !== undefined;
    }, [assistantCode, portState, progress]);
    const isFwSelectButtonDisabled = useMemo(() => {
        return assistantCode === undefined || progress !== undefined;
    }, [assistantCode, progress]);
    const isDeviceInfoVisible = useMemo(() => {
        return portState === CONNECTION_STATE_CONNECTED && autoDetect && deviceInfo !== undefined;
    }, [portState, autoDetect, deviceInfo]);
    const isWriteFwButtonDisabled = useMemo(() => {
        return assistantCode === undefined || !(portState === CONNECTION_STATE_CONNECTED && assistantState === STATE_CLIENT_ONLINE && fwData && (progress === undefined || progress >= 100 || progress < 0));
    }, [assistantCode, portState, assistantState, fwData, progress]);
    const isTerminalDisabled = useMemo(() => {
        return assistantCode === undefined || !(portState === CONNECTION_STATE_CONNECTED && assistantState === STATE_CLIENT_ONLINE && progress === undefined);
    }, [assistantCode, portState, assistantState, progress]);
    const zipFile = useMemo(() => {
        return fwData?.subFiles?.map(sf => {
            return {name: sf.name, type: sf.type, content: new ArrayBuffer(0)};
        })
    }, [fwData?.subFiles]);
    return (
        <div className="container-grow d-flex flex-column m-4">
            <div className="container-grow d-flex flex-column">
                <div className="container-grow d-flex flex-column">
                    <div className="container-grow d-flex flex-row">
                        <div className="flex-grow-1 d-flex align-self-stretch">
                            <div className="w-100 d-flex flex-column">
                                <h5 className="mx-2">{t("loader")}</h5>
                                <table className="loader-controls-table w-100">
                                    <tbody>
                                    <tr>
                                        <td width="120px" className="px-2">
                                            <Button className="btn btn-primary btn-sm w-100"
                                                    onClick={toggleConnection}
                                                    disabled={isConnectButtonDisabled}>{t(portState === CONNECTION_STATE_NOT_CONNECTED ? "loader_connect" : "loader_disconnect")}</Button>
                                        </td>
                                        <td colSpan={2} className="px-2">
                                            <Form.Control size="sm" type="text" className="w-100 text-center" readOnly
                                                          disabled
                                                          value={formatConnectionState(t, portState)}/>
                                        </td>
                                    </tr>
                                    {(!isDeviceInfoVisible) &&
                                        <Fragment>
                                            <tr>
                                                <td colSpan={3} className="px-2">
                                                    <div className="d-flex flex-row align-items-center">
                                                        <Form.Check type={"checkbox"} label={t("loader_low_speed")}
                                                                    checked={slowDevice}
                                                                    disabled={assistantCode === undefined || portState !== CONNECTION_STATE_NOT_CONNECTED || autoDetect}
                                                                    onChange={() => toggleSlowDevice(!slowDevice)}/>
                                                        {!(assistantCode === undefined || portState !== CONNECTION_STATE_NOT_CONNECTED || autoDetect) &&
                                                            <HelpButton text={t('loader_low_speed_description')}/>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} className="px-2">
                                                    <div className="d-flex flex-row align-items-center">
                                                        <Form.Check type={"checkbox"} label={t("loader_autodetect")}
                                                                    checked={autoDetect}
                                                                    disabled={(assistantCode === undefined) || (portState !== CONNECTION_STATE_NOT_CONNECTED)}
                                                                    onChange={() => toggleAutoDetect(!autoDetect)}/>
                                                        {!((assistantCode === undefined) || (portState !== CONNECTION_STATE_NOT_CONNECTED)) &&
                                                            <HelpButton text={t('loader_autodetect_description')}/>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    }
                                    {isDeviceInfoVisible &&
                                        <Fragment>
                                            <tr style={{height: 50}}>
                                                <td colSpan={3} className="px-2">
                                                    <div className={"d-flex flex-column align-items-center"}>
                                                        {(deviceInfo === null || displayDeviceData === undefined) &&
                                                            <Form.Label>{t("loader_autodetect_failed")}</Form.Label>}
                                                        {deviceInfo && displayDeviceData &&
                                                            <Form.Label>{displayDeviceData}</Form.Label>}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr style={{height: 50}}/>
                                        </Fragment>
                                    }
                                    <tr>
                                        <td width="120px" className="px-2">
                                            <DropdownButton size="sm" className="w-100" title={t("loader_select_fw")}
                                                            disabled={isFwSelectButtonDisabled}>
                                                <Dropdown.Item
                                                    onClick={selectLocalFile}>{t("loader_select_file")}</Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={toggleFwSelectDialog}>{t("loader_select_from_cloud")}</Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={toggleFwSearchDialog}
                                                    disabled={deviceInfo === undefined}>{t("loader_search_fw")}</Dropdown.Item>
                                            </DropdownButton>
                                        </td>
                                        <td colSpan={2} className="px-2">
                                            <Form.Control size="sm" type="text" className="w-100 text-center" readOnly
                                                          disabled
                                                          value={fwName}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3} className="px-2">
                                            <Form.Label
                                                className={`status ${status.isError ? "status-error" : ""}`}
                                                dangerouslySetInnerHTML={{__html: status.text}}/>
                                        </td>
                                    </tr>
                                    {(progress !== undefined && (progress >= 100 || progress < 0)) &&
                                        <td colSpan={3} className="px-2">
                                            <div className="d-flex justify-content-center">
                                                <Button className="btn btn-primary btn-sm"
                                                        disabled={isWriteFwButtonDisabled}
                                                        onClick={() => writeFw()}>{t("continue")}</Button>
                                            </div>
                                        </td>
                                    }
                                    {(progress === undefined || (progress < 100 && progress >= 0)) &&
                                        <tr>
                                            {fwTransferProgress === undefined &&
                                                <td colSpan={2} className="px-2">
                                                    <ProgressBar className="w-100" min={0} max={100}
                                                                 now={Math.abs(progress ?? 0)}/>
                                            </td>
                                        }
                                        {fwTransferProgress !== undefined &&
                                            <td colSpan={2} className="px-2">
                                                <ProgressBar className="w-100" min={0} max={100}
                                                             now={fwTransferProgress ?? 0}/>
                                            </td>
                                        }
                                        <td width="120px" className="px-2">
                                            <Button className="btn btn-primary btn-sm w-100"
                                                    disabled={isWriteFwButtonDisabled}
                                                    onClick={() => writeFw()}>{t("loader_fw_write")}</Button>
                                        </td>
                                    </tr>
                                    }
                                    </tbody>
                                </table>
                                <div className="container-grow d-flex flex-row">
                                    <div className="container-grow d-flex flex-column">
                                        <h5 className="mx-2">{t("terminal")}</h5>
                                        <div className="d-flex container-grow">
                                            <div className="d-flex flex-grow-1 px-2">
                                                <Form.Control ref={terminalOutputRef} as="textarea" size="sm"
                                                              className="flex-grow-1 output-text-area"
                                                              onScroll={terminalOutputOnScroll}
                                                              readOnly
                                                              disabled value={terminalText}/>
                                            </div>
                                            <table className="loader-controls-table">
                                                <tbody>
                                                <tr>
                                                    <td className="px-2">
                                                        <Form.Control ref={terminalInputRef} size="sm" type="text"
                                                                      className="w-100"
                                                                      disabled={isTerminalDisabled}
                                                                      onKeyDown={handleTerminalInputKeyDown}/>
                                                    </td>
                                                    <td width="120px" className="px-2">
                                                        <Button className="btn btn-primary btn-sm w-100"
                                                                disabled={isTerminalDisabled}
                                                                onClick={sendTerminalString}>{t("terminal_send")}</Button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"d-flex flex-row side-panel"}>
                                <VerticalDivider className="mx-2"/>
                                <div className="flex-grow-1 d-flex flex-column align-self-stretch">
                                    <h5 className="mx-2 text-center">{t("loader_remote_assistance")}</h5>
                                    <table className="loader-controls-table w-100">
                                        <tbody>
                                        <tr>
                                            <td>
                                                <Button className="mx-2 w-100"
                                                        onClick={assistHandler}>{assistantCode ? t("loader_fw_disconnect_support") : t("loader_fw_connect_session")}</Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {assistantCode &&
                                                    <Form.Label
                                                        className={"w-100 text-center"}>{t("loader_assistance_request_code")}</Form.Label>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {assistantCode &&
                                                    <Form.Label
                                                        className={"w-100 text-center fw-bold"}>{assistantCode}</Form.Label>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {assistantCode &&
                                                    <Form.Label
                                                        className={"w-100 text-center"}>{t("loader_assistance_state")}</Form.Label>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {assistantCode &&
                                                    <Form.Label
                                                        className={"w-100 text-center fw-bold"}>{formatMqttState(t, assistantState)}</Form.Label>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td/>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div className="d-flex container-grow">
                                        <h5 className="mx-2">{t("chat")}</h5>
                                        <div className="d-flex flex-grow-1 px-2">
                                            <Form.Control ref={chatOutputRef} as="textarea" size="sm"
                                                          className="flex-grow-1 output-text-area"
                                                          onScroll={chatOutputOnScroll}
                                                          readOnly
                                                          disabled value={chatText}/>
                                        </div>
                                        <table className="loader-controls-table">
                                            <tbody>
                                            <tr>
                                                <td className="px-2">
                                                    <Form.Control ref={chatInputRef} size="sm" type="text"
                                                                  className="w-100"
                                                                  disabled={assistantCode === undefined || !canSendChat}
                                                                  onKeyDown={handleChatInputKeyDown}/>
                                                </td>
                                                <td width="120px" className="px-2">
                                                    <Button className="btn btn-primary btn-sm w-100"
                                                            disabled={assistantCode === undefined || !canSendChat}
                                                            onClick={sendChatString}>{t("terminal_send")}</Button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FwSelectDialog show={showFwSelectDialog} closeHandler={toggleFwSelectDialog}
                                fwSelectionHandler={onCloudFwReady} deviceType={deviceInfo?.deviceTypeId}/>
                <FwSearchDialog show={showFindFwDialog} closeHandler={toggleFwSearchDialog} deviceInfo={deviceInfo!}
                                fwSelectionHandler={onCloudFwReady}/>
                <ZipFileSelectDialog show={showZipFileSelectDialog} closeHandler={toggleZipFileSelectDialog}
                                     zipFileSelectionHandler={onZipFwReady} zipFile={zipFile}/>
                <FwAttentionDialog message={attentionDialogMessage} closeHandler={fwWriteConfirmationHandle}/>
                <StartAssistDialog show={showStartAssistDialog}
                                   closeHandler={assistStartDialogHandler}/>
                <StopAssistDialog show={showStopAssistDialog} closeHandler={assistStopDialogHandler}/>
                <AssistConnectDialog show={showAssistConnectDialog} closeHandler={assistConnectDialogHandler}
                                     mqttClient={mqttClient} code={assistantCode} devices={availableDevices}/>
                <FwTransferDialog mqttClient={mqttClient} code={assistantCode} fwInfo={fwInfo} fwName={fwFileName}
                                  fwType={fwFileType}
                                  fwData={fwTransferContent} closeHandler={fwTransferHandler}/>
            </div>
        </div>
    );
}